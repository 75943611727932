import React from "react";
import { Link } from "react-router-dom";

import "./remote.css";

let current_hostname = window.location.hostname;
let current_protocol = window.location.protocol;
let current_port = window.location.port;
if (current_hostname !== 'localhost'){
  current_protocol='https:';
  current_port = 443;
}
let current_root_url = current_protocol+'//'+current_hostname+':'+current_port+'/'

export const Error = () => {

  return (
      <>
        <header>
          <div className="container">
              <a href="/" title="Go Main"><img className="title-wide" src="/grodi_logo.png" alt="GrodiTech Remote" /></a>
              <a href="/" title="Go Main"><img className="title-portrait" src="/grodi_logo_corto.png" alt="GrodiTech Remote" /></a>
              <span className="text-title">REMOTE</span>
          </div>
        </header>
        <div className='container text-center'>
          <div className="mainlist p-1">
            <p className="error404">404</p>
            <p className="text-title">Página no encontrada</p>
          </div>
          <p className="index-title text-center">
            <Link className="basic-link" to={current_root_url}>
              Volver a la página de inicio
            </Link>
          </p>
        </div>
        </>
  );
};
