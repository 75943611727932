import axios from 'axios';
import config from './config.json';

const userAPI  = axios.create({
    baseURL: config.USER_API_URL
});
userAPI.defaults.baseURL = config.USER_API_URL;

const vegaAPI  = axios.create({
    baseURL: config.VEGA_API_URL
});
vegaAPI.defaults.baseURL = config.VEGA_API_URL;

const mediaURL = config.MEDIA_URL;

export { userAPI, vegaAPI, mediaURL };
