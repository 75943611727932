import React, { useEffect } from "react";
import { Devices } from "./pages/Devices";
import { Monitor } from "./pages/Monitor";
import { Error } from "./pages/Error";
import { Redirect } from "./pages/Redirect";
import { Route, Routes } from "react-router-dom";
import { userAPI } from "./api";
import { jwtDecode } from "jwt-decode";
import config from "./config";
import Cookies from "js-cookie";

export const AppRoutes = () => {
  let token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      // Check expiration date
      const expdate = jwtDecode(token).exp;
      if (expdate * 1000 < Date.now()) {
        Cookies.remove("token", { domain: '.groditech.com', expires: 7 });
        window.location.reload();
      }
      else if (((expdate * 1000) - Date.now()) < 518400000) {
        // Extend expiration date
        userAPI
          .get( "/user/tokenupdate", {headers: {Authorization: token}})
          .then((res) => {
            token = res.data.token;
            Cookies.set("token", token, { domain: '.groditech.com', expires: 7 });
          })
          .catch((error) => {
            Cookies.remove("token", { domain: '.groditech.com', expires: 7 });
            window.location.reload();
          });
      }
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      userAPI
        .post(`/user/checkUserApp`, {app_id: 2}, {headers: {authorization: token}})
        .then((res) => {
          if (res.status === 201) {
          }
        })
        .catch((error) => {
          console.log('ERROR: checkUserApp');
        });
    }
  }, [token]);

  return (
    <Routes>
      {!token && (
        <>
          <Route path="*" element={<Redirect url={`${config.AUTH_URL}/login?backurl=${window.location.protocol}//${window.location.host}`} />} />
        </>
      )}
      {token && (
        <>
          <Route path="/" element={<Devices token={token} />} />
          <Route path="/monitor/:device_id" element={<Monitor token={token} />} />
          <Route path="/logout" element={<Redirect url={`${config.AUTH_URL}/logout?backurl=${window.location.protocol}//${window.location.host}`} />} />
          <Route path="*" element={<Error />} />
        </>
      )}
    </Routes>
  );
};
